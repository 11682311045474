import { computed, ref } from 'vue';
import { cloneDeep } from 'lodash';
import BaseFilterControls from './BaseFilterControls';
import { trackIdPassesFilter } from './AttributeTrackFilterControls';
export default class TrackFilterControls extends BaseFilterControls {
    constructor(params) {
        super(params);
        this.attributeFilters = ref([]);
        this.userDefinedValues = ref([]);
        this.enabledFilters = ref([]);
        /**
         * Override filtered track annotations to include logic
         * for filtering based on group membership as well
         */
        this.filteredAnnotations = computed(() => {
            const checkedSet = new Set(this.checkedTypes.value);
            const filteredGroupsSet = new Set(params.groupFilterControls.enabledAnnotations.value
                .map((v) => v.annotation.id));
            const confidenceFiltersVal = cloneDeep(this.confidenceFilters.value);
            const resultsArr = [];
            const resultsIds = new Set();
            params.sorted.value.forEach((annotation) => {
                let enabledInGroupFilters = true;
                const groups = params.lookupGroups(annotation.id);
                if (groups.length) {
                    /**
                     * This track is a member of a group,
                     * so check that at least one of its groups is enabled
                     */
                    enabledInGroupFilters = groups.some((group) => filteredGroupsSet.has(group.id));
                }
                const confidencePairIndex = annotation.confidencePairs
                    .findIndex(([confkey, confval]) => {
                    const confidenceThresh = Math.max(confidenceFiltersVal[confkey] || 0, confidenceFiltersVal.default);
                    return confval >= confidenceThresh && checkedSet.has(confkey);
                });
                /* include annotations where at least 1 confidence pair is above
                 * the threshold and part of the checked type set */
                if ((confidencePairIndex >= 0 || annotation.confidencePairs.length === 0)
                    && enabledInGroupFilters && !resultsIds.has(annotation.id)) {
                    let addValue = true;
                    if (this.attributeFilters.value.length > 0 && params.getTrack !== undefined
                        && this.enabledFilters.value.length > 0) {
                        addValue = trackIdPassesFilter(annotation.id, params.getTrack, this.attributeFilters.value, this.userDefinedValues.value, this.enabledFilters.value);
                    }
                    if (addValue) {
                        resultsIds.add(annotation.id);
                        resultsArr.push({
                            annotation,
                            context: {
                                confidencePairIndex,
                            },
                        });
                    }
                }
            });
            return resultsArr;
        });
    }
    loadTrackAttributesFilter(trackAttributesFilter) {
        this.attributeFilters.value = [];
        this.userDefinedValues.value = [];
        this.enabledFilters.value = [];
        trackAttributesFilter.forEach((element) => {
            this.attributeFilters.value.push(element);
            this.userDefinedValues.value.push(element.filter.userDefined ? element.filter.val : null);
            this.enabledFilters.value.push(element.enabled);
        });
    }
    updateTrackFilter(index, val) {
        if (index < this.attributeFilters.value.length) {
            this.attributeFilters.value.splice(index, 1, val);
            this.userDefinedValues.value.splice(index, 1, val.filter.userDefined ? val.filter.val : null);
            this.enabledFilters.value.splice(index, 1, val.enabled);
        }
        else {
            this.attributeFilters.value.push(val);
            this.userDefinedValues.value.push(val.filter.userDefined ? val.filter.val : null);
            this.enabledFilters.value.push(val.enabled);
        }
        this.markChangesPending({ action: 'upsert', attributeTrackFilter: val });
    }
    deleteTrackFilter(index) {
        if (index < this.attributeFilters.value.length) {
            const items = this.attributeFilters.value.splice(index, 1);
            this.userDefinedValues.value.splice(index, 1);
            this.enabledFilters.value.splice(index, 1);
            this.markChangesPending({ action: 'delete', attributeTrackFilter: items[0] });
        }
    }
    setUserDefinedValue(index, val) {
        if (index < this.userDefinedValues.value.length) {
            this.userDefinedValues.value.splice(index, 1, val);
        }
    }
    setEnabled(index, val) {
        if (index < this.enabledFilters.value.length) {
            this.enabledFilters.value.splice(index, 1, val);
        }
    }
}
