import { computed } from 'vue';
function updateHistogram(begin, end, histogram) {
    const beginval = histogram[begin];
    const endval = histogram[end];
    return [
        beginval === undefined ? 1 : beginval + 1,
        endval === undefined ? -1 : endval - 1,
    ];
}
export default function useLineChart({ enabledTracks, typeStyling, allTypes, }) {
    const lineChartData = computed(() => {
        /* Histogram map contains multiple histograms keyed
         * on a type name, which comes from the list of
         * enabled types
         */
        const histograms = new Map();
        histograms.set('total', [0]);
        allTypes.value.forEach((t) => histograms.set(t, [0]));
        /* In order to populate the histograms, generate and solve a
         * sort of "balanced parenthesis" problem.
         * For each begin time, push a 1, for each end time, push a -1.
         * Then iterate each histogram and generate its accumulation at each point.
         */
        enabledTracks.value.forEach((filtered) => {
            const { annotation: track } = filtered;
            const totalArr = histograms.get('total');
            const ibegin = track.begin;
            const iend = track.end > track.begin ? track.end : track.begin + 1;
            [totalArr[ibegin], totalArr[iend]] = updateHistogram(ibegin, iend, totalArr);
            const trackType = track.getType(filtered.context.confidencePairIndex);
            const typeArr = histograms.get(trackType);
            [typeArr[ibegin], typeArr[iend]] = updateHistogram(ibegin, iend, typeArr);
        });
        const mapfunc = typeStyling.value.color;
        /* Now, each histograms array looks like this:
         *   [1, 2, 0, -2, -1]
         * We want to accumulate each array left-to-right so it looks like this:
         *   [1, 3, 3, 1, 0]
         */
        return Array.from(histograms.entries())
            .map(([type, arr]) => {
            const accumulatedHistogram = [];
            arr.reduce((prev, current, i) => {
                accumulatedHistogram.push([i, prev + current]);
                return prev + current;
            }, 0);
            return {
                values: accumulatedHistogram,
                color: type === 'total' ? 'lime' : mapfunc(type),
                name: type,
            };
        });
    });
    return { lineChartData };
}
