import { ref, computed, watch, } from 'vue';
import { updateSubset } from './utils';
export const DefaultConfidence = 0.1;
/* Provide annotation filtering controls on annotations loaded from store. */
export default class BaseFilterControls {
    constructor(params) {
        this.checkedIDs = ref(params.sorted.value.map((t) => t.id));
        this.confidenceFilters = ref({ default: DefaultConfidence });
        this.defaultTypes = ref([]);
        this.sorted = params.sorted;
        this.remove = params.remove;
        this.setType = params.setType;
        this.removeTypes = params.removeTypes;
        this.markChangesPending = params.markChangesPending;
        this.allTypes = computed(() => {
            const typeSet = new Set();
            this.sorted.value.forEach((annotation) => {
                annotation.confidencePairs.forEach(([name]) => {
                    typeSet.add(name);
                });
            });
            this.defaultTypes.value.forEach((type) => {
                typeSet.add(type);
            });
            return Array.from(typeSet);
        });
        this.usedTypes = computed(() => {
            const typeSet = new Set();
            this.sorted.value.forEach((annotation) => {
                annotation.confidencePairs.forEach(([name]) => {
                    typeSet.add(name);
                });
            });
            return Array.from(typeSet);
        });
        this.checkedTypes = ref(Array.from(this.allTypes.value));
        this.filteredAnnotations = ref([]);
        this.enabledAnnotations = computed(() => {
            const checkedSet = new Set(this.checkedIDs.value);
            return this.filteredAnnotations.value
                .filter((filtered) => checkedSet.has(filtered.annotation.id));
        });
        // because vue watchers don't behave properly, and it's better to not have
        // checkedIDs be a union null | array type
        let oldCheckedIds = [];
        /* When the list of types (or checked IDs) changes
        * add the new enabled types to the set and remove old ones */
        watch(params.sorted, (newval) => {
            const IDs = newval.map((t) => t.id);
            const newArr = updateSubset(oldCheckedIds, IDs, this.checkedIDs.value);
            if (newArr !== null) {
                oldCheckedIds = IDs;
                this.checkedIDs.value = newArr;
            }
        });
        let oldCheckedtypes = [];
        watch(this.usedTypes, (newval) => {
            const newArr = updateSubset(oldCheckedtypes, newval, this.checkedTypes.value);
            if (newArr !== null) {
                oldCheckedtypes = Array.from(newval);
                this.checkedTypes.value = newArr;
            }
        });
    }
    importTypes(types, userInteraction = true) {
        types.forEach((type) => {
            if (!this.defaultTypes.value.includes(type)) {
                this.defaultTypes.value.push(type);
            }
        });
        if (userInteraction) {
            this.markChangesPending({ action: 'meta' });
        }
    }
    deleteType(type) {
        if (this.defaultTypes.value.includes(type)) {
            this.defaultTypes.value.splice(this.defaultTypes.value.indexOf(type), 1);
        }
        delete this.confidenceFilters.value[type];
        this.markChangesPending({ action: 'meta' });
    }
    setConfidenceFilters(val) {
        if (val) {
            this.confidenceFilters.value = val;
        }
    }
    updateTypeName({ currentType, newType }) {
        //Go through the entire list and replace the oldType with the new Type
        this.sorted.value.forEach((annotation) => {
            for (let i = 0; i < annotation.confidencePairs.length; i += 1) {
                const [name, confidenceVal] = annotation.confidencePairs[i];
                if (name === currentType) {
                    this.setType(annotation.id, newType, confidenceVal, currentType);
                    break;
                }
            }
        });
        if (!(newType in this.confidenceFilters.value) && currentType in this.confidenceFilters.value) {
            this.setConfidenceFilters({
                ...this.confidenceFilters.value,
                [newType]: this.confidenceFilters.value[currentType],
            });
        }
        this.deleteType(currentType);
    }
    removeTypeAnnotations(types) {
        this.filteredAnnotations.value.forEach((filtered) => {
            const filteredType = filtered.annotation.getType(filtered.context.confidencePairIndex);
            if (filteredType && types.includes(filteredType[0])) {
                //Remove the type from the annotation if multiple types exist
                const newConfidencePairs = this.removeTypes(filtered.annotation.id, types);
                if (newConfidencePairs.length === 0) {
                    this.remove(filtered.annotation.id);
                }
            }
        });
    }
    updateCheckedTypes(types) {
        this.checkedTypes.value = types;
    }
    updateCheckedId(id, value) {
        if (value) {
            this.checkedIDs.value.push(id);
        }
        else {
            const i = this.checkedIDs.value.indexOf(id);
            this.checkedIDs.value.splice(i, 1);
        }
    }
}
