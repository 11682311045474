import { reactive } from 'vue';
/* Components */
import TypeThreshold from 'dive-common/components/TypeThreshold.vue';
import ImageEnhancements from 'vue-media-annotator/components/ImageEnhancements.vue';
import GroupSidebar from 'dive-common/components/GroupSidebar.vue';
import AttributesSideBar from 'dive-common/components/Attributes/AttributesSideBar.vue';
import MultiCamTools from 'dive-common/components/MultiCamTools.vue';
import AttributeTrackFilters from 'vue-media-annotator/components/AttributeTrackFilters.vue';
const state = reactive({
    last: 'TypeThreshold',
    active: null,
    subCategory: null,
});
const componentMap = {
    [TypeThreshold.name]: {
        description: 'Threshold Controls',
        component: TypeThreshold,
    },
    [ImageEnhancements.name]: {
        description: 'Image Enhancements',
        component: ImageEnhancements,
    },
    [GroupSidebar.name]: {
        description: 'Group Manager',
        component: GroupSidebar,
    },
    [MultiCamTools.name]: {
        description: 'Multi Camera Tools',
        component: MultiCamTools,
    },
    [AttributesSideBar.name]: {
        description: 'Attribute Details',
        component: AttributesSideBar,
    },
    [AttributeTrackFilters.name]: {
        description: 'Attribute Track Filters',
        component: AttributeTrackFilters,
    },
};
function register(item) {
    componentMap[item.component.name || 'default'] = item;
}
function unregister(item) {
    if (componentMap[item.component.name || 'default']) {
        delete componentMap[item.component.name || 'default'];
    }
}
function resetActive() {
    state.last = 'TypeThreshold';
    state.active = null;
}
function getComponents() {
    const components = {};
    Object.values(componentMap).forEach((v) => {
        components[v.component.name || 'default'] = v.component;
    });
    return components;
}
function toggle(active) {
    if (active === undefined) {
        if (state.active) {
            state.active = null;
        }
        else {
            state.active = state.last;
        }
    }
    else if (active && state.active === active) {
        state.active = null;
    }
    else if (active === null || active in componentMap) {
        state.active = active;
        if (active) {
            state.last = active;
        }
    }
    else {
        throw new Error(`${active} is not a valid context component`);
    }
    window.dispatchEvent(new Event('resize'));
}
function openClose(active, action, subCategory) {
    if (action) {
        if (state.active) {
            state.last = state.active;
        }
        state.active = active;
    }
    else {
        if (state.active) {
            state.last = state.active;
            state.subCategory = null;
        }
        state.active = null;
    }
    if (subCategory) {
        state.subCategory = subCategory;
    }
}
export default {
    toggle,
    openClose,
    register,
    unregister,
    getComponents,
    resetActive,
    componentMap,
    state,
};
