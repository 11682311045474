import { render, staticRenderFns } from "./ImportMultiCamAddType.vue?vue&type=template&id=f5ba80e8&scoped=true"
import script from "./ImportMultiCamAddType.vue?vue&type=script&lang=ts"
export * from "./ImportMultiCamAddType.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5ba80e8",
  null
  
)

export default component.exports