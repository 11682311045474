var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-progress-linear',{style:({ visibility: _vm.loading ? 'visible' : 'hidden' }),attrs:{"indeterminate":_vm.loading,"height":"6"}}),_c('v-container',{attrs:{"fill-height":"","fluid":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-row',{staticClass:"fill-height nowraptable"},[_c('v-col',{attrs:{"cols":"3"}},[_c('DataDetails',{attrs:{"value":_vm.selected.length ? _vm.selected : [_vm.location]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('Clone',_vm._b({attrs:{"dataset-id":_vm.locationInputs.length === 1 ? _vm.locationInputs[0] : null}},'Clone',{ buttonOptions: _vm.buttonOptions, menuOptions: _vm.menuOptions },false)),_c('run-training-menu',_vm._b({attrs:{"selected-dataset-ids":_vm.locationInputs}},'run-training-menu',{
                  buttonOptions:
                    { ..._vm.buttonOptions, disabled: _vm.includesLargeImage },
                  menuOptions: _vm.menuOptions,
                },false)),_c('run-pipeline-menu',_vm._b({attrs:{"selected-dataset-ids":_vm.locationInputs,"running-pipelines":_vm.runningPipelines}},'run-pipeline-menu',{
                  buttonOptions:
                    { ..._vm.buttonOptions, disabled: _vm.includesLargeImage },
                  menuOptions: _vm.menuOptions,
                },false)),_c('export',_vm._b({attrs:{"dataset-ids":_vm.locationInputs}},'export',{ buttonOptions: _vm.buttonOptions, menuOptions: _vm.menuOptions },false)),_c('v-btn',_vm._b({attrs:{"disabled":!_vm.selected.length,"color":"error"},on:{"click":_vm.deleteSelection}},'v-btn',{ ..._vm.buttonOptions },false),[_c('v-icon',[_vm._v(" mdi-delete ")]),_c('span',{staticClass:"pl-1"},[_vm._v(" Delete ")])],1)],1)]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":9}},[_c('v-toolbar',{staticClass:"mb-4",attrs:{"dense":"","rounded":""}},[_c('ShareTab',{attrs:{"value":0}})],1),_c('router-view'),(_vm.selectedDescription)?_c('v-card',{staticClass:"my-4"},[_c('GirderMarkdown',{staticClass:"pa-3",attrs:{"text":_vm.selectedDescription}})],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }