var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.selectedAttributes)?_c('panel-subsection',[_c('template',{slot:"header"},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"dense":""}},[_c('b',{staticClass:"attribute-header"},[_vm._v(_vm._s(_vm.mode)+" Attributes")]),(_vm.mode === 'Detection')?_c('div',{staticClass:"text-caption",attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(`Frame: ${_vm.frameRef}`)+" ")]):_vm._e()]),_c('v-tooltip',{attrs:{"open-delay":"200","bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","disabled":_vm.readOnlyMode},on:{"click":_vm.addAttribute}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,3528641628)},[_c('span',[_vm._v("Add a new "+_vm._s(_vm.mode)+" Attribute")])]),_c('v-tooltip',{attrs:{"open-delay":"200","bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","icon":"","color":_vm.activeSettings ? 'accent' : ''},on:{"click":function($event){return _vm.toggleActiveSettings()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,false,3884864411)},[_c('span',[_vm._v("Show/Hide un-used")])]),_c('tooltip-btn',{attrs:{"icon":_vm.sortingMethodIcons[_vm.sortingMode],"tooltip-text":"Sort types by value or alphabetically"},on:{"click":_vm.clickSortToggle}}),_c('tooltip-btn',{attrs:{"icon":"mdi-filter","color":_vm.filtersActive ? 'primary' : 'default',"tooltip-text":_vm.filtersActive
          ? 'Filters are active, click to view' : 'No filters are active, click to edit'},on:{"click":_vm.openFilter}}),(_vm.mode === 'Detection')?_c('tooltip-btn',{attrs:{"icon":"mdi-chart-line-variant","color":_vm.timelineEnabled ? 'primary' : 'default',"tooltip-text":"Timeline Settings for Attributes"},on:{"click":_vm.openTimeline}}):_c('div',{staticClass:"blank-spacer"})],1)],1),(_vm.selectedAttributes)?_c('template',{slot:"scroll-section"},[(
        _vm.activeSettings || _vm.activeAttributesCount
      )?_c('v-col',{staticClass:"pa-0"},_vm._l((_vm.filteredFullAttributes),function(attribute){return _c('span',{key:attribute.name},[(
            _vm.activeSettings
              || _vm.selectedAttributes.attributes[attribute.name] !== undefined
          )?_c('v-row',{staticClass:"ma-0",attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"attribute-name"},[_c('div',{staticClass:"type-color-box",style:({
              backgroundColor: attribute.color,
            })}),_c('span',[_vm._v(_vm._s(attribute.name)+": ")])]),_c('v-col',{staticClass:"px-1"},[(_vm.activeSettings)?_c('AttributeInput',{attrs:{"datatype":attribute.datatype,"name":attribute.name,"disabled":_vm.readOnlyMode,"values":attribute.values ? attribute.values : null,"value":_vm.getAttributeValue(attribute),"type-settings":attribute.editor || null},on:{"change":function($event){return _vm.updateAttribute($event, attribute)}}}):_c('div',[(_vm.editIndividual != attribute)?_c('div',{staticClass:"attribute-item-value",on:{"click":function($event){$event.stopPropagation();return _vm.setEditIndividual(attribute)}}},[_vm._v(" "+_vm._s(_vm.getAttributeValue(attribute))+" ")]):_c('div',[_c('AttributeInput',{attrs:{"datatype":attribute.datatype,"name":attribute.name,"disabled":_vm.readOnlyMode,"values":attribute.values ? attribute.values : null,"value":_vm.getAttributeValue(attribute),"type-settings":attribute.editor || null,"focus":""},on:{"change":function($event){return _vm.updateAttribute($event, attribute)}}})],1)])],1),(_vm.activeSettings)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.editAttribute(attribute)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-cog ")])],1)],1):_vm._e()],1):_vm._e()],1)}),0):_c('v-col',[_c('div',{staticStyle:{"font-size":"0.75em"}},[_vm._v(" No "+_vm._s(_vm.mode)+" attributes set ")])])],1):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }