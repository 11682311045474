/* disabled this rule for Vue.prototype.FOO = */
/* eslint-disable no-param-reassign,func-names */
import { watch } from 'vue';
import Prompt from './Prompt.vue';
class PromptService {
    constructor(Vue, vuetify) {
        const PromptComponent = Vue.extend({ vuetify, ...Prompt });
        const component = new PromptComponent();
        this.component = component;
    }
    set(title, text, positiveButton, negativeButton, confirm, resolve) {
        this.component.title = title;
        this.component.text = text;
        this.component.positiveButton = positiveButton;
        this.component.negativeButton = negativeButton;
        this.component.confirm = confirm;
        this.component.functions.resolve = resolve;
        this.component.show = true;
    }
    show({ title, text, positiveButton = 'Confirm', negativeButton = 'Cancel', confirm = false, }) {
        return new Promise((resolve) => {
            if (!this.component.show) {
                this.set(title, text, positiveButton, negativeButton, confirm, resolve);
            }
            else {
                const unwatch = watch(this.component.show, () => {
                    unwatch();
                    this.set(title, text, positiveButton, negativeButton, confirm, resolve);
                });
            }
        });
    }
    visible() {
        return this.component.show;
    }
    invisible() {
        return !this.component.show;
    }
    hide() {
        this.component.show = false;
    }
    mount(element) {
        this.component.$mount(element);
    }
}
// in vue 3 should use provide/inject with symbol
let promptService;
export function usePrompt() {
    // in vue 3 should use inject instead of singleton
    const prompt = (params) => promptService.show(params);
    const visible = () => promptService.visible();
    const invisible = () => promptService.invisible();
    const hide = () => promptService.hide();
    return {
        prompt, visible, invisible, hide,
    };
}
export default function (vuetify) {
    return function install(Vue) {
        // in vue 3 should use provide instead of singleton
        promptService = new PromptService(Vue, vuetify);
        Vue.prototype.$promptAttach = function () {
            const div = document.createElement('div');
            this.$el.appendChild(div);
            if (promptService) {
                promptService.mount(div);
            }
            return this;
        };
    };
}
