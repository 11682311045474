var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"attribute-settings"},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" Attributes "),_c('v-card-text',[_c('v-card-title',{staticClass:"text-h6"},[_c('v-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',[_vm._v(" Main ")]),_c('v-tab',[_vm._v(" Rendering ")]),(['text', 'number'].includes(_vm.baseSettings.datatype))?_c('v-tab',[_vm._v(" Value Colors ")]):_vm._e()],1)],1),_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',[(_vm.error || !_vm.addNew)?_c('v-alert',{attrs:{"type":_vm.error ? 'error' : 'info'}},[_c('div',{staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.error ? _vm.error : 'Changes to Attribute Datatypes or Names do not effect \ currently set attributes on tracks.')+" ")])]):_vm._e(),_c('v-form',{ref:"form",model:{value:(_vm.baseSettings.areSettingsValid),callback:function ($$v) {_vm.$set(_vm.baseSettings, "areSettingsValid", $$v)},expression:"baseSettings.areSettingsValid"}},[_c('v-text-field',{attrs:{"label":"Name","rules":[v => !!v || 'Name is required', v => !v.includes(' ')
                || 'No spaces', v => v !== 'userAttributes' || 'Reserved Name'],"required":""},model:{value:(_vm.baseSettings.name),callback:function ($$v) {_vm.$set(_vm.baseSettings, "name", $$v)},expression:"baseSettings.name"}}),_c('v-select',{attrs:{"value":_vm.baseSettings.datatype,"items":[
                { text: 'Boolean', value: 'boolean' },
                { text: 'Number', value: 'number' },
                { text: 'Text', value: 'text' },
              ],"label":"Datatype"},on:{"change":_vm.typeChange}}),(_vm.baseSettings.datatype === 'number')?_c('div',[_c('v-radio-group',{attrs:{"value":(_vm.baseSettings.editor && _vm.baseSettings.editor.type) || 'combo',"row":"","label":"Display Type:"},on:{"change":_vm.numericChange}},[_c('v-radio',{attrs:{"label":"Input Box","value":"combo"}}),_c('v-radio',{attrs:{"label":"Slider","value":"slider"}})],1)],1):_vm._e(),(false)?_c('div',[_c('v-checkbox',{attrs:{"label":"User Attribute","hint":"Attribute data is saved per user instead of globally.","persistent-hint":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1):_vm._e(),(_vm.baseSettings.datatype === 'number'
                && _vm.baseSettings.editor && _vm.baseSettings.editor.type === 'slider')?_c('div',[(_vm.baseSettings.editor.range)?_c('v-row',{staticClass:"pt-2"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","step":_vm.baseSettings.editor.range[0] > 1 ? 1 : 0.01,"type":"number","label":"Min","rules":[
                    v => !isNaN(parseFloat(v)) || 'Number is required',
                    v => _vm.baseSettings.editor
                      && _vm.baseSettings.editor.type === 'slider'
                      && _vm.baseSettings.editor.range
                      && v < _vm.baseSettings.editor.range[1]
                      || 'Min needs to be smaller than the Max'],"max":_vm.baseSettings.editor.range[1],"hint":"Min limit for slider","persistent-hint":""},model:{value:(_vm.baseSettings.editor.range[0]),callback:function ($$v) {_vm.$set(_vm.baseSettings.editor.range, 0, _vm._n($$v))},expression:"baseSettings.editor.range[0]"}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","step":_vm.baseSettings.editor.range[1] > 1 ? 1 : 0.01,"type":"number","label":"Max","rules":[
                    v => !isNaN(parseFloat(v)) || 'Number is required',
                    v => _vm.baseSettings.editor
                      && _vm.baseSettings.editor.type === 'slider'
                      && _vm.baseSettings.editor.range
                      && v > _vm.baseSettings.editor.range[0]
                      || 'Max needs to be larger than the Min'],"min":_vm.baseSettings.editor.range[0],"hint":"Max limit for slider","persistent-hint":""},model:{value:(_vm.baseSettings.editor.range[1]),callback:function ($$v) {_vm.$set(_vm.baseSettings.editor.range, 1, _vm._n($$v))},expression:"baseSettings.editor.range[1]"}})],1):_vm._e(),_c('v-row',{staticClass:"pt-2"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","step":_vm.baseSettings.editor
                    && _vm.baseSettings.editor.steps && _vm.baseSettings.editor.steps > 1 ? 1 : 0.01,"type":"number","rules":[
                    v => !isNaN(parseFloat(v)) || 'Number is required',
                    v => _vm.baseSettings.editor
                      && _vm.baseSettings.editor.type === 'slider'
                      && _vm.baseSettings.editor.range
                      && v < (_vm.baseSettings.editor.range[1] - _vm.baseSettings.editor.range[0])
                      || 'Steps should be smaller than the range'],"label":"Slider Step Interval","min":"0","hint":"Each movement will move X amount","persistent-hint":""},model:{value:(_vm.baseSettings.editor.steps),callback:function ($$v) {_vm.$set(_vm.baseSettings.editor, "steps", _vm._n($$v))},expression:"baseSettings.editor.steps"}})],1)],1):_vm._e(),(_vm.baseSettings.datatype === 'text')?_c('v-textarea',{attrs:{"label":"Predefined values","hint":"Line separated values","outlined":"","auto-grow":"","row-height":"30"},model:{value:(_vm.textValues),callback:function ($$v) {_vm.textValues=$$v},expression:"textValues"}}):_vm._e()],1),(!_vm.colorEditor)?_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',{attrs:{"align-self":"center","cols":"2"}},[_c('h2',[_vm._v(" Color: ")])]),_c('v-col',{attrs:{"align-self":"center"}},[(!_vm.colorSettings.color)?_c('div',{staticClass:"edit-color-box",style:({
                  backgroundColor: _vm.colorSettings.tempColor,
                }),on:{"click":_vm.launchColorEditor}}):_c('div',{staticClass:"edit-color-box",style:({
                  backgroundColor: _vm.colorSettings.color,
                }),on:{"click":_vm.launchColorEditor}})]),_c('v-spacer')],1):_vm._e(),(_vm.colorEditor)?_c('v-row',[_c('v-spacer'),_c('v-col',[_c('v-color-picker',{attrs:{"hide-inputs":""},model:{value:(_vm.colorSettings.color),callback:function ($$v) {_vm.$set(_vm.colorSettings, "color", $$v)},expression:"colorSettings.color"}})],1),_c('v-spacer')],1):_vm._e()],1),_c('v-tab-item',[_c('v-switch',{attrs:{"label":"Rendering"},model:{value:(_vm.attributeRendering),callback:function ($$v) {_vm.attributeRendering=$$v},expression:"attributeRendering"}}),(_vm.attributeRendering && _vm.renderingVals !== undefined)?_c('attribute-rendering',{attrs:{"attribute":_vm.selectedAttribute},model:{value:(_vm.renderingVals),callback:function ($$v) {_vm.renderingVals=$$v},expression:"renderingVals"}}):_vm._e()],1),(_vm.baseSettings.datatype === 'text')?_c('v-tab-item',[_c('attribute-value-colors',{attrs:{"attribute":_vm.selectedAttribute},on:{"save":function($event){_vm.colorSettings.attributeColors = $event}}})],1):(_vm.baseSettings.datatype === 'number')?_c('v-tab-item',[_c('attribute-number-value-colors',{attrs:{"attribute":_vm.selectedAttribute},on:{"save":function($event){_vm.colorSettings.attributeColors = $event}}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-row',[_c('v-tooltip',{attrs:{"open-delay":"100","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"hover-show-child",attrs:{"color":"error","disabled":!_vm.selectedAttribute.key.length},on:{"click":function($event){$event.preventDefault();return _vm.deleteAttribute.apply(null, arguments)}}},[_vm._v(" Delete ")])],1)]}}])},[_c('span',{staticClass:"ma-0 pa-1"},[_vm._v(" Deletion of Attribute ")])]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.baseSettings.areSettingsValid
              || (!_vm.baseSettings.name || _vm.baseSettings.name.includes(' '))},on:{"click":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }