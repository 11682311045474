import { inject } from 'vue';
export function useStore() {
    const store = inject('store');
    if (store === undefined) {
        throw new Error('Store was undefined');
    }
    return store;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isGirderModel(value) {
    return value._id && value._modelType;
}
