import { readonly, ref } from 'vue';
import { useApi } from 'dive-common/apispec';
function _updatePendingChangeMap(key, value, action, upsert, del) {
    if (action === 'delete') {
        del.add(key);
        upsert.delete(key);
    }
    else if (action === 'upsert') {
        del.delete(key);
        upsert.set(key, value);
    }
}
export default function useSave(datasetId, readonlyMode) {
    const pendingSaveCount = ref(0);
    const pendingChangeMaps = {
        singleCam: {
            upsert: new Map(),
            delete: new Set(),
            attributeUpsert: new Map(),
            attributeDelete: new Set(),
            groupUpsert: new Map(),
            groupDelete: new Set(),
            attributeTrackFilterUpsert: new Map(),
            attributeTrackFilterDelete: new Set(),
            meta: 0,
        },
    };
    const { saveDetections, saveMetadata, saveAttributes, saveAttributeTrackFilters, } = useApi();
    async function save(datasetMeta, set) {
        if (readonlyMode.value) {
            throw new Error('attempted to save in read only mode');
        }
        const promiseList = [];
        let globalMetadataUpdated = false;
        Object.entries(pendingChangeMaps).forEach(([camera, pendingChangeMap]) => {
            const saveId = camera === 'singleCam' ? datasetId.value : `${datasetId.value}/${camera}`;
            if (pendingChangeMap.upsert.size
                || pendingChangeMap.delete.size
                || pendingChangeMap.groupUpsert.size
                || pendingChangeMap.groupDelete.size) {
                promiseList.push(saveDetections(saveId, {
                    tracks: {
                        upsert: Array.from(pendingChangeMap.upsert).map((pair) => pair[1].serialize()),
                        delete: Array.from(pendingChangeMap.delete),
                    },
                    groups: {
                        upsert: Array.from(pendingChangeMap.groupUpsert).map((pair) => pair[1].serialize()),
                        delete: Array.from(pendingChangeMap.groupDelete),
                    },
                    set,
                }).then(() => {
                    pendingChangeMap.upsert.clear();
                    pendingChangeMap.delete.clear();
                }));
            }
            if (datasetMeta && pendingChangeMap.meta > 0) {
                // Save once for each camera into their own metadata file
                promiseList.push(saveMetadata(saveId, datasetMeta).then(() => {
                    // eslint-disable-next-line no-param-reassign
                    pendingChangeMap.meta = 0;
                }));
                // Only update global if there are multiple cameras
                if (saveId !== datasetId.value) {
                    globalMetadataUpdated = true;
                }
            }
            if (pendingChangeMap.attributeUpsert.size || pendingChangeMap.attributeDelete.size) {
                promiseList.push(saveAttributes(datasetId.value, {
                    upsert: Array.from(pendingChangeMap.attributeUpsert).map((pair) => pair[1]),
                    delete: Array.from(pendingChangeMap.attributeDelete),
                }).then(() => {
                    pendingChangeMap.attributeUpsert.clear();
                    pendingChangeMap.attributeDelete.clear();
                }));
            }
            if (pendingChangeMap.attributeTrackFilterUpsert.size
                || pendingChangeMap.attributeTrackFilterDelete.size) {
                promiseList.push(saveAttributeTrackFilters(datasetId.value, {
                    upsert: Array.from(pendingChangeMap.attributeTrackFilterUpsert).map((pair) => pair[1]),
                    delete: Array.from(pendingChangeMap.attributeTrackFilterDelete),
                }).then(() => {
                    pendingChangeMap.attributeTrackFilterUpsert.clear();
                    pendingChangeMap.attributeTrackFilterDelete.clear();
                }));
            }
        });
        // Final save into the multi-cam metadata if multiple cameras exists
        if (globalMetadataUpdated && datasetMeta && pendingChangeMaps) {
            promiseList.push(saveMetadata(datasetId.value, datasetMeta));
        }
        await Promise.all(promiseList);
        pendingSaveCount.value = 0;
    }
    function markChangesPending({ action, track, attribute, attributeTrackFilter, group, cameraName = 'singleCam', } = { action: 'meta' }) {
        // For meta changes we need to indicate to all cameras that there is change.
        // Meta changes are global across all cameras
        if (action === 'meta') {
            Object.values(pendingChangeMaps).forEach((pendingChangeMap) => {
                // eslint-disable-next-line no-param-reassign
                pendingChangeMap.meta += 1;
            });
            pendingSaveCount.value += 1;
        }
        else if (pendingChangeMaps[cameraName]) {
            const pendingChangeMap = pendingChangeMaps[cameraName];
            if (!readonlyMode.value) {
                if (track !== undefined) {
                    _updatePendingChangeMap(track.trackId, track, action, pendingChangeMap.upsert, pendingChangeMap.delete);
                }
                else if (attribute !== undefined) {
                    _updatePendingChangeMap(attribute.key, attribute, action, pendingChangeMap.attributeUpsert, pendingChangeMap.attributeDelete);
                }
                else if (attributeTrackFilter !== undefined) {
                    _updatePendingChangeMap(attributeTrackFilter.name, attributeTrackFilter, action, pendingChangeMap.attributeTrackFilterUpsert, pendingChangeMap.attributeTrackFilterDelete);
                }
                else if (group !== undefined) {
                    _updatePendingChangeMap(group.id, group, action, pendingChangeMap.groupUpsert, pendingChangeMap.groupDelete);
                }
                else {
                    throw new Error(`Arguments inconsistent with pending change type: ${action} cannot be performed without additional arguments`);
                }
                pendingSaveCount.value += 1;
            }
        }
    }
    function discardChanges() {
        Object.values(pendingChangeMaps).forEach((pendingChangeMap) => {
            pendingChangeMap.upsert.clear();
            pendingChangeMap.delete.clear();
            pendingChangeMap.attributeUpsert.clear();
            pendingChangeMap.attributeDelete.clear();
            pendingChangeMap.groupUpsert.clear();
            pendingChangeMap.groupDelete.clear();
            // eslint-disable-next-line no-param-reassign
            pendingChangeMap.meta = 0;
        });
        pendingSaveCount.value = 0;
    }
    function addCamera(cameraName) {
        pendingChangeMaps[cameraName] = {
            upsert: new Map(),
            delete: new Set(),
            attributeUpsert: new Map(),
            attributeDelete: new Set(),
            groupUpsert: new Map(),
            groupDelete: new Set(),
            attributeTrackFilterUpsert: new Map(),
            attributeTrackFilterDelete: new Set(),
            meta: 0,
        };
    }
    function removeCamera(cameraName) {
        if (pendingChangeMaps[cameraName]) {
            delete pendingChangeMaps[cameraName];
        }
    }
    return {
        save,
        markChangesPending,
        discardChanges,
        pendingSaveCount: readonly(pendingSaveCount),
        addCamera,
        removeCamera,
    };
}
