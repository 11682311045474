import { provide } from 'vue';
import { use } from 'vue-media-annotator/provides';
const DatasetMetaMutableKeys = ['attributes', 'confidenceFilters', 'customTypeStyling', 'customGroupStyling', 'attributeTrackFilters'];
const ApiSymbol = Symbol('api');
/**
 * provideApi specifies an implementation of the data persistence interface
 * for use in vue-web-common
 * @param api an api implementation
 */
function provideApi(api) {
    provide(ApiSymbol, api);
}
function useApi() {
    return use(ApiSymbol);
}
export { provideApi, useApi, };
export { DatasetMetaMutableKeys, };
