var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex align-center px-1",style:({
      background: `linear-gradient(
          to right,
          #2c759650 ${((_vm.frame - _vm.begin) / (_vm.end - _vm.begin) * 100).toFixed(0)}%,
          rgba(0,0,0,0) ${(1 - ((_vm.frame - _vm.begin) / (_vm.end - _vm.begin)) * 100, 0).toFixed(0)}%)`,
    })},[_c('v-text-field',{staticClass:"px-2 mt-0",staticStyle:{"width":"100%"},attrs:{"value":_vm.begin,"disabled":_vm.disabled,"single-line":"","dense":"","type":"number","label":"Begin frame","hide-details":"","min":_vm.min,"max":Math.min(_vm.end, _vm.max),"rules":[
        (v) => v <= Math.min(_vm.end, _vm.max) || 'Begin must be less than end and max',
        (v) => v >= _vm.min || 'Begin must be >= min',
      ]},on:{"input":_vm.updateBegin},scopedSlots:_vm._u([(!_vm.disabled)?{key:"append-outer",fn:function(){return [_c('tooltip-btn',{attrs:{"icon":"mdi-map-marker","tooltip-text":`Set range start to current frame (${_vm.frame})`,"size":"x-small","delay":100,"disabled":_vm.frame < _vm.min || _vm.frame > Math.min(_vm.end, _vm.max)},on:{"click":function($event){return _vm.$emit('click:begin')}}})]},proxy:true}:null],null,true)}),_c('v-text-field',{staticClass:"px-2 mt-0",staticStyle:{"width":"100%"},attrs:{"value":_vm.end,"disabled":_vm.disabled,"hide-details":"","single-line":"","dense":"","type":"number","label":"End frame","min":Math.max(_vm.begin, _vm.min),"max":_vm.max,"rules":[
        (v) => v >= Math.max(_vm.begin, _vm.min) || 'End must be >= begin and min',
        (v) => v <= _vm.max || 'End must be <= max',
      ]},on:{"input":_vm.updateEnd},scopedSlots:_vm._u([(!_vm.disabled)?{key:"append-outer",fn:function(){return [_c('tooltip-btn',{attrs:{"icon":"mdi-map-marker","delay":100,"size":"x-small","tooltip-text":`Set range end to current frame (${_vm.frame})`,"disabled":_vm.frame < Math.max(_vm.begin, _vm.min) || _vm.frame > _vm.max},on:{"click":function($event){return _vm.$emit('click:end')}}})]},proxy:true}:null],null,true)}),(!_vm.disabled && _vm.last)?_c('tooltip-btn',{attrs:{"icon":"mdi-clock-plus","tooltip-text":"Add new sub-range","delay":100,"disabled":_vm.frame < _vm.min || _vm.frame > _vm.max,"size":"x-small"},on:{"click":function($event){return _vm.$emit('click:add-range')}}}):_vm._e(),(!_vm.disabled && !_vm.last)?_c('tooltip-btn',{attrs:{"icon":"mdi-clock-minus","tooltip-text":"Remove sub-range","delay":100,"size":"x-small"},on:{"click":function($event){return _vm.$emit('click:remove-range')}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }