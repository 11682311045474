import { ref, computed, set as VueSet, del as VueDel, } from 'vue';
export default function UseAttributes({ markChangesPending, trackStyleManager, selectedTrackId, cameraStore, }) {
    const attributes = ref({});
    const attributeFilters = ref({ track: [], detection: [] });
    const timelineFilter = ref({
        appliedTo: ['all'],
        active: true,
        value: true,
        type: 'key',
    });
    const timelineEnabled = ref(false);
    function loadAttributes(metadataAttributes) {
        attributes.value = metadataAttributes;
        Object.values(attributes.value).forEach((attribute) => {
            if (attribute.color === undefined) {
                // eslint-disable-next-line no-param-reassign
                attribute.color = trackStyleManager.typeStyling.value.color(attribute.name);
            }
        });
    }
    const attributesList = computed(() => Object.values(attributes.value));
    function setAttribute({ data, oldAttribute }, updateAllTracks = false) {
        if (oldAttribute && data.key !== oldAttribute.key) {
            // Name change should delete the old attribute and create a new one with the updated id
            VueDel(attributes.value, oldAttribute.key);
            markChangesPending({ action: 'delete', attribute: oldAttribute });
            // Create a new attribute to replace it
        }
        if (oldAttribute === undefined) {
            // eslint-disable-next-line no-param-reassign
            data.color = trackStyleManager.typeStyling.value.color(data.name);
        }
        if (updateAllTracks && oldAttribute) {
            // TODO: Lengthy track/detection attribute updating function
        }
        VueSet(attributes.value, data.key, data);
        markChangesPending({ action: 'upsert', attribute: attributes.value[data.key] });
    }
    function deleteAttribute({ data }, removeFromTracks = false) {
        if (attributes.value[data.key] !== undefined) {
            markChangesPending({ action: 'delete', attribute: attributes.value[data.key] });
            VueDel(attributes.value, data.key);
        }
        if (removeFromTracks) {
            // TODO: Lengthty track/detection attribute deletion function
        }
    }
    function addAttributeFilter(index, type, filter) {
        const filterList = attributeFilters.value[type];
        filterList.push(filter);
        VueSet(attributeFilters.value, type, filterList);
    }
    function deleteAttributeFilter(index, type) {
        const filterList = attributeFilters.value[type];
        if (index < filterList.length) {
            filterList.splice(index, 1);
        }
        else {
            throw Error(`Index: ${index} is out of range for the ${type} filter list of length ${filterList.length}`);
        }
    }
    function modifyAttributeFilter(index, type, filter) {
        const filterList = attributeFilters.value[type];
        if (index < filterList.length) {
            filterList[index] = filter;
            VueSet(attributeFilters.value, type, filterList);
        }
        else {
            throw Error(`Index: ${index} is out of range for the ${type} filter list of length ${filterList.length}`);
        }
    }
    function sortAttributes(attributeList, mode, attribVals, sortingMode) {
        const filteredAttributes = Object.values(attributeList).filter((attribute) => attribute.belongs === mode);
        return filteredAttributes.sort((a, b) => {
            if (sortingMode === 0) {
                return (a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
            }
            const aVal = attribVals[a.name];
            const bVal = attribVals[b.name];
            if (aVal === undefined && bVal === undefined) {
                return 0;
            }
            if (aVal === undefined && bVal !== undefined) {
                return 1;
            }
            if (aVal !== undefined && bVal === undefined) {
                return -1;
            }
            if (a.datatype === 'number' && b.datatype === 'number') {
                return bVal - aVal;
            }
            if (a.datatype === 'number' && b.datatype !== 'number') {
                return -1;
            }
            if (a.datatype !== 'number' && b.datatype === 'number') {
                return 1;
            }
            return (a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
        });
    }
    function applyStringFilter(filter, item, val) {
        if (filter.comp === '=') {
            return filter.value.includes(val);
        }
        if (filter.comp === '≠') {
            return !filter.value.includes(val);
        }
        if (filter.comp === 'contains') {
            return filter.value.reduce((prev, str) => prev || str.includes(val), false);
        }
        if (filter.comp === 'starts') {
            return filter.value.reduce((prev, str) => prev || str.startsWith(val), false);
        }
        return true;
    }
    function applyNumberFilter(filter, item, val, index) {
        if (filter.type === 'range') {
            if (filter.comp === '>') {
                return (val > filter.value);
            }
            if (filter.comp === '<') {
                return (val < filter.value);
            }
            if (filter.comp === '<=') {
                return (val <= filter.value);
            }
            if (filter.comp === '>=') {
                return (val >= filter.value);
            }
            return true;
        }
        if (filter.type === 'top') {
            return index < filter.value;
        }
        return true;
    }
    function applyKeyFilter(filter, item) {
        if (filter.appliedTo.includes(item.name) || filter.appliedTo.includes('all')) {
            return true;
        }
        return false;
    }
    function filterAttributes(attributeList, mode, attribVals, filters) {
        let sortedFilteredAttributes = attributeList;
        filters.forEach((filter) => {
            if (filter.filterData.active) {
                sortedFilteredAttributes = sortedFilteredAttributes.filter((item, index) => {
                    // Filter on appliedTo list of attributes or 'all'
                    if (filter.dataType !== 'key' && (filter.filterData.appliedTo.includes(item.name) || filter.filterData.appliedTo[0] === 'all')) {
                        if (filter.dataType === 'number' && item.datatype === 'number') {
                            const numberFilter = filter.filterData;
                            return applyNumberFilter(numberFilter, item, attribVals[item.name], index);
                        }
                        if (filter.dataType === 'text' && item.datatype === 'text') {
                            const stringFilter = filter.filterData;
                            return applyStringFilter(stringFilter, item, attribVals[item.name]);
                        }
                        return true;
                    }
                    if (filter.dataType === 'key') {
                        const keyFilter = filter.filterData;
                        return applyKeyFilter(keyFilter, item);
                    }
                    return true;
                });
            }
            return sortedFilteredAttributes;
        });
        return sortedFilteredAttributes;
    }
    /**
     * Used for display purposes of the Attributes in the sideBar. If you are rendering
     * Attributes for track  and want the filters applied it may be better to filter
     * only on existing values in AttribVals instead of the entire object This takes
     * the Attributes built in Sorts them by Name or Numeric value and then filters them
     * based on the filters that have are active.
     * @param attributeList list of tempalated attributes
     * @param mode - detection or tack
     * @param attribVals - the attribute values for the track/detection
     * @param sortingMode - 0 = alphabetical, 1 = numeric
     * @param filters - list of filters to applie
     * @returns - sorted list of attributes
     */
    function sortAndFilterAttributes(attributeList, mode, attribVals, sortingMode, filters) {
        const sortedAttributes = sortAttributes(attributeList, mode, attribVals, sortingMode);
        const filteredAttributes = filterAttributes(sortedAttributes, mode, attribVals, filters);
        return filteredAttributes;
    }
    function generateDetectionTimelineData(track, filter) {
        // Generate a list of all of the attributres for the length of the track
        const valueMap = {};
        track.features.forEach((feature) => {
            const { frame } = feature;
            if (feature.attributes) {
                Object.keys(feature.attributes).forEach((key) => {
                    if (feature.attributes && (filter.appliedTo.includes(key) || filter.appliedTo.includes('all'))) {
                        const val = feature.attributes[key];
                        if (val === undefined) {
                            return;
                        }
                        if (valueMap[key] === undefined) {
                            let dataType = 'text';
                            const data = {
                                values: [],
                                name: key,
                                color: attributes.value[`detection_${key}`].color || 'white',
                            };
                            if (typeof (val) === 'number') {
                                dataType = 'number';
                            }
                            else if (typeof (val) === 'boolean') {
                                dataType = 'boolean';
                            }
                            valueMap[key] = {
                                data,
                                maxFrame: -Infinity,
                                minFrame: Infinity,
                                type: dataType,
                            };
                        }
                        if (valueMap[key].type === 'number') {
                            valueMap[key].data.values.push([
                                frame,
                                val,
                            ]);
                        }
                        if (valueMap[key].type === 'number') {
                            if (valueMap[key].minValue === undefined || valueMap[key].maxValue === undefined) {
                                valueMap[key].minValue = Infinity;
                                valueMap[key].maxValue = -Infinity;
                            }
                            valueMap[key].minValue = Math.min(valueMap[key].minValue, val);
                            valueMap[key].maxValue = Math.max(valueMap[key].maxValue, val);
                        }
                        valueMap[key].minFrame = Math.min(valueMap[key].minFrame, frame);
                        valueMap[key].maxFrame = Math.max(valueMap[key].maxFrame, frame);
                    }
                });
            }
        });
        return valueMap;
    }
    const attributeTimelineData = computed(() => {
        if (selectedTrackId.value !== null && timelineEnabled.value && timelineFilter.value !== null) {
            const selectedTrack = cameraStore.getAnyPossibleTrack(selectedTrackId.value);
            if (selectedTrack) {
                const timelineData = generateDetectionTimelineData(selectedTrack, timelineFilter.value);
                // Need to convert any Number types to Line Chart data;
                const numberVals = Object.values(timelineData).filter((item) => item.type === 'number');
                return numberVals;
            }
        }
        return [];
    });
    function setTimelineEnabled(val) {
        timelineEnabled.value = val;
    }
    function setTimelineFilter(val) {
        timelineFilter.value = val;
    }
    return {
        loadAttributes,
        attributesList,
        setAttribute,
        deleteAttribute,
        addAttributeFilter,
        deleteAttributeFilter,
        modifyAttributeFilter,
        attributeFilters,
        sortAndFilterAttributes,
        setTimelineEnabled,
        setTimelineFilter,
        attributeTimelineData,
        timelineFilter,
        timelineEnabled,
    };
}
